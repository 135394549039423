import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_plugin_mjs_1QO0gqa6n2 from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_head_runtime_plugin_mjs_1QO0gqa6n2,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc
]