import { meta as _47opt_47buildhome_47repo_47pages_47index_46vueMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    file: "/opt/buildhome/repo/pages/index.vue",
    children: [],
    meta: _47opt_47buildhome_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47buildhome_47repo_47pages_47index_46vueMeta?.alias || [],
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]